import Vue from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import Home from './views/Home.vue';
import Login from './views/Login.vue';
import Studio from './views/Studio.vue';
import ListBuilder from './views/ListBuilder.vue';
import RemoteListing from './views/RemoteListing.vue';
import DetailBuilder from './views/DetailBuilder.vue';
import Business from './components/forms/Business.vue';
import LocationSelector from './components/LocationSelector.vue';
import ActivitiesPrintout from './components/ActivitiesPrintout.vue';
import Analytics from './components/Analytics.vue';
import Marketing from './components/Marketing.vue';
import Calendar from './components/Calendar.vue';
import CalendarBuilder from './components/CalendarBuilder.vue';
import MapEdit from './components/MapEdit.vue';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import MarketingAssetEditor from './components/MarketingAssetEditor';
import LegacyEventsBuilder from './views/builders/LegacyEventsBuilder.vue';
import EventsBuilder from './views/builders/EventsBuilder.vue';
import MessagesBuilder from './views/builders/MessagesBuilder.vue';
import EntriesBuilder from './views/builders/EntriesBuilder.vue';
import ManageCalendars from './components/ManageCalendars.vue';
import FlyerBuilder from './components/FlyerBuilder.vue';
import Event from './components/forms/Event.vue';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/login',
      name: 'Login',
      component: Login,
      meta: {
        requiresAuth: false,
        noLeftColumn: true,
      },
    },
    {
      path: '/studio',
      name: 'Studio',
      component: Studio,
      meta: {
        title: 'AppMyCommunity Studio',
        requiresAuth: false,
        noLeftColumn: true,
      },
    },
    {
      path: '/activities-printout/:calendarId?',
      name: 'activitiesPrintout',
      component: ActivitiesPrintout,
      meta: {
        requiresAuth: true,
        noLeftColumn: true,
      },
    },
    {
      path: '/analytics',
      name: 'analytics',
      component: Analytics,
      meta: {
        requiresAuth: true,
        noLeftColumn: true,
      },
    },
    {
      path: '/mapedit/:path',
      name: 'mapedit',
      component: MapEdit,
      meta: {
        requiresAuth: false,
        noLeftColumn: true,
      },
    },
    {
      path: '/marketing',
      name: 'marketing',
      component: Marketing,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/marketing/asset-editor',
      name: 'marketingAssetEditor',
      component: MarketingAssetEditor,
      meta: {
        requiresAuth: true,
        noLeftColumn: true,
      },
    },
    {
      path: '/calendar',
      name: 'calendar',
      component: Calendar,
      meta: {
        requiresAuth: false,
        noLeftColumn: true,
      },
    },
    {
      path: '/calendarBuilder',
      name: 'calendarBuilder',
      component: CalendarBuilder,
      meta: {
        requiresAuth: true,
        noLeftColumn: true,
      },
    },
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/:action',
      name: 'home/action',
      component: Home,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/home/:action/:itemId',
      name: 'home/edit',
      component: Home,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/listnm/:context',
      name: 'listingNoMenu',
      component: ListBuilder,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/listnm/:context/:path',
      name: 'listingNoMenuFolders',
      component: ListBuilder,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/listnm/remote/:context/:uuid',
      name: 'remoteListingNoMenu',
      component: ListBuilder,
      meta: {
        isRemote: true,
        requiresAuth: false,
      },
    },
    {
      path: '/listnm/:context/add',
      name: 'listingNoMenuAdd',
      component: ListBuilder,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/listnm/:context/:action/:itemId',
      name: 'listingNoMenuEdit',
      component: ListBuilder,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/list/:menuId/:gridMenuKey/:tab/:action/:itemId',
      name: 'listingEdit',
      component: ListBuilder,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/list/:menuId/:gridMenuKey/:tab/:action',
      name: 'listingAdd',
      component: ListBuilder,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/list/:menuId/:gridMenuKey',
      name: 'listing',
      component: ListBuilder,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/list/:menuId/:gridMenuKey/:tab',
      name: 'listingTab',
      component: ListBuilder,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/detail/remote',
      name: 'remoteUpdate',
      component: RemoteListing,
      meta: {
        isRemote: true,
        requiresAuth: true,
      },
    },
    {
      path: '/detail/remote/:uuid/',
      name: 'remoteUpdateNoKey',
      component: Business,
      meta: {
        isRemote: true,
        requiresAuth: false,
      },
    },
    {
      path: '/detail/remote/id/:detailId',
      name: 'remoteUpdateDetail',
      component: Business,
      meta: {
        isRemote: true,
        requiresAuth: false,
      },
    },
    {
      path: '/amc-calendar/remote/:uuid/',
      name: 'remoteCalendarNoKey',
      component: EventsBuilder,
      meta: {
        isRemote: true,
        requiresAuth: false,
      },
    },
    {
      path: '/amc-calendar/remote/id/:detailId/',
      name: 'remoteCalendar',
      component: EventsBuilder,
      meta: {
        isRemote: true,
        requiresAuth: false,
      },
    },
    {
      path: '/event/remote/:dbContext',
      name: 'remoteEvent',
      component: Event,
      meta: {
        isRemote: true,
        requiresAuth: false,
      },
    },
    {
      path: '/event/remote/:dbContext/:itemId',
      name: 'remoteEventEdit',
      component: Event,
      meta: {
        isRemote: true,
        requiresAuth: false,
      },
    },
    {
      path: '/detail/:type/:dbContext',
      name: 'detailAdd',
      component: DetailBuilder,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/detailUpdate/:communityKey/:type/:dbContext/:itemId',
      name: 'detailUpdate',
      component: DetailBuilder,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/detailUpdate/:communityKey/:type/:dbContext/id/:detailId',
      name: 'detailUpdate',
      component: DetailBuilder,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/detail/:type/:dbContext/:itemId',
      name: 'detailEdit',
      component: DetailBuilder,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/detail/:type/:dbContext/:itemId/:detailAction',
      name: 'detailAction',
      component: DetailBuilder,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/detail/:type/:dbContext/:itemId?',
      name: 'detailDuplicate',
      component: DetailBuilder,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/location',
      name: 'location',
      component: LocationSelector,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/amc-calendar/menu/:menu?/:menuIndex?/:tab?/:action?',
      name: 'amc-calendar-old',
      component: LegacyEventsBuilder,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/amc-calendar/:context/:view/:action?',
      name: 'amc-calendar',
      component: EventsBuilder,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/amc-calendars/:action?',
      name: 'amc-calendars',
      component: ManageCalendars,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/amc-messages/:context/:action?',
      name: 'amc-messages',
      component: MessagesBuilder,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/scrollerEntries/:context/:action/:itemId',
      name: 'scrollerEntries',
      component: EntriesBuilder,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/create-flyer/:action?',
      name: 'createFlyer',
      component: FlyerBuilder,
      meta: {
        requiresAuth: true,
        noLeftColumn: true,
      },
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  const currentUser = firebase.auth().currentUser;
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  Vue.nextTick(() => {
    document.title =
      to.meta.title ||
      (window.localStorage.getItem('CAMPGROUND_NAME') ? `App Admin - ${window.localStorage.getItem('CAMPGROUND_NAME')}` : 'App Admin');
  });
  if (requiresAuth && !currentUser && !firebase.auth().isSignInWithEmailLink(window.location.href)) {
    next('login');
  } else {
    next();
  }
});

export default router;
