<template>
  <div class="two-column">
    <div id="center-column" @scroll="onScroll">
      <template v-if="readyToView">
        <div style="padding: 8px 16px; border-bottom: 1px solid black">
          <div style="display: flex; align-items: baseline">
            <input
              id="bulk-select-checkbox"
              v-model="bulkCheckAll"
              type="checkbox"
              class="bulk-select"
              :indeterminate="bulkSelected.length !== 0 && bulkSelected.length !== chunkKeys.length" />

            <div class="simple-title list-item" style="line-height: 40px" data-bs-toggle="collapse" :data-bs-target="'#filter'">
              <!-- Set label to :for bulk-select -->
              <label for="bulk-select-checkbox" data-bs-toggle="collapse" :data-bs-target="'#placebo-filter'" style="width: 65px">Select All</label>
              <button
                v-if="filterReady?.any()"
                type="button"
                class="btn btn-secondary my-0 p-1 w-100"
                data-bs-toggle="collapse"
                :data-bs-target="'#placebo-filter'"
                @click.prevent="filterReady?.clear()">
                Clear Filters
              </button>
              <button
                v-if="bulkCheckAll || bulkSelected.length > 0"
                type="button"
                class="btn btn-secondary my-0 p-1 w-100"
                data-bs-toggle="collapse"
                :data-bs-target="'#placebo-filter'"
                @click="deleteSelected">
                Delete Selected
              </button>
              <i class="material-icons expand"></i>
            </div>
          </div>
          <FilterList
            @filterText="(val) => (filterText = val)"
            @filterDate="
              (start, end) => {
                filterStartDate = start;
                filterEndDate = end;
              }
            "
            @clear-filters="clearFilters()"
            @ready="(ready) => (filterReady = ready)" />
          <template v-if="Object.keys(eventFilterData).length > 1">
            <div class="row">
              <div class="col">
                <label class="mb-2"> RSVP Event Filter</label><br />
                <select v-model="filterEvent" class="form-select">
                  <option value="">All Events</option>
                  <option v-for="(event, id) in eventFilterData" :key="id" :value="id">
                    {{ event.eventTitle }}
                  </option>
                </select>
              </div>
            </div>
          </template>
          <div v-else-if="Object.keys(eventFilterData).length == 1">
            <div class="row">
              <div class="col">
                <label class="mb-2"> RSVP for Event: {{ eventFilterData[Object.keys(eventFilterData)[0]]?.eventTitle }}</label
                ><br />
              </div>
            </div>
          </div>
          <div v-if="bulkSelected.length > 0" class="bulk-selected">
            <span>Mark all:</span>
            <button v-if="view == 'view-archived'" class="btn m-1 btn-secondary" @click="markAll(completedValues.open)">Reopened</button>
            <button class="btn m-1 btn-primary" @click="markAll(completedValues.completed)">Completed</button>
            <button class="btn m-1 btn-secondary" @click="markAll(completedValues.denied)">Denied</button>
          </div>
        </div>
        <div v-if="rsvpStats">Total Attending: {{ rsvpStats.attendingCount }}</div>
        <div v-if="rsvpStats">Total Yes Response: {{ rsvpStats.responseYes }}</div>
        <div v-if="rsvpStats">Total No Response: {{ rsvpStats.responseCount - rsvpStats.responseYes }}</div>
        <ul
          v-for="(element, index) in chunkKeys"
          :id="displayType == 'categorygrid' ? 'grid-container' : 'items'"
          :key="index"
          :class="{ 'hide-actions': action }">
          <li :class="displayType == 'categorygrid' ? '' : 'item'">
            <component
              :is="centerComponent"
              :item="chunkList[index]"
              :prev-item-id="chunkKeys[index - 1]"
              :list-icons="listIcons"
              :item-id="element"
              :db-context="dbContext"
              :display-type="displayType"
              :bulk-selected="bulkSelected"
              :in-process="view == 'in-process'"
              @deleteItem="deleteItem"
              @action="setAction"
              @editItem="editItem"
              @bulkCheckItem="bulkCheckItem" />
          </li>
        </ul>
      </template>
    </div>
    <div id="right-column" class="p-2">
      <component
        :is="rightColumnComponent"
        v-bind="rightColumnProps"
        :key="rightColumnKey"
        :right-column-key="rightColumnKey"
        :action="action"
        :view-archive="view == 'view-archived'"
        :in-process="view == 'in-process'"
        @action="setAction" />
    </div>
  </div>
</template>

<script>
import axios from 'axios';

import InfiniteScrollMixin from '@/components/mixins/InfiniteScrollMixin.js';
import { typeMap, newObjectMap } from '@/json/listBuilderData.json';
import ButtonNavigation from '@/components/ButtonNavigation.vue';
import FilterList from '@/components/inputfields/FilterList.vue';
import firebase from 'firebase/compat/app';

//Entries
import EntryItems from '@/components/listitems/EntryItems.vue';
import FormEntry from '@/components/forms/FormEntry.vue';
import HuntEntry from '@/components/forms/HuntEntry.vue';

export default {
  components: {
    ButtonNavigation,
    EntryItems,
    FormEntry,
    HuntEntry,
    FilterList,
  },
  mixins: [InfiniteScrollMixin],
  data() {
    return {
      completedValues: {
        completed: 2,
        open: 0,
        denied: 1,
      },
      readyToView: false,
      type: null,
      listIcons: [],
      tabIndex: 0,
      itemId: null,
      entryData: {},
      eventFilterData: {},
      rsvpStats: null,
      actions: {
        default: () => {
          this.rightColumnProps = {
            buttonSection: 'actions',
            context: this.context + '.entries',
            dbContext: this.dbContext,
            displayType: this.displayType,
          };
          if (this.displayType) {
            this.rightColumnComponent = 'ButtonNavigation';
          }
        },
      },
      filterReady: null,
    };
  },
  async created() {
    this.addActions();

    const route = this.$route.params;
    this.itemId = route.itemId;
    this.context = route.context;
    this.type = this.context + '.entries';
    this.displayType = typeMap[this.type][this.tabIndex].displayType;

    // await this.getLocations();
  },
  async mounted() {
    this.chunkSize = 25;
    this.setupView('view-active');
    await this.getUserEntryData(this.itemId);
    await this.setAction();

    if (this.userEntryData?.item?.type === 'rsvp') {
      this.filterEvent = this.$route.query.eventId || '';
      this.$nextTick(() => {
        this.eventFilterData = this.chunkList.reduce(
          (acc, { eventId, eventTitle, eventStartDate, eventContext }) => (
            (acc[eventId] = { eventTitle: `${eventTitle} - ${this.getFormattedDate(eventStartDate)}`, eventContext }), acc
          ),
          {}
        );
      });
    }
  },
  methods: {
    addActions() {
      this.addAction('edit', async () => {
        this.rightColumnProps = {
          ...(this.userEntryData || {}),
          ...{
            entry: this.getItemByKey(this.rightColumnKey),
            entryId: this.rightColumnKey,
          },
        };
        this.rightColumnComponent = this.rightColumnProps.displayType;
      });

      //Views
      this.addAction('view-active', async () => {
        this.setupView('view-active');
      });
      this.addAction('view-archived', async () => {
        this.setupView('view-archived');
      });
      this.addAction('in-process', async () => {
        this.setupView('in-process');
      });
      this.addAction('submitted', async () => {
        this.setupView('submitted');
      });
      this.addAction('delete-item', async () => {
        this.deleteItem(this.rightColumnKey);
      });
      this.addAction('mark-item', async (args) => {
        const [_, value, entryId, entry] = args;
        let dbRef = this.getUserWrittenLocationContext().child(this.dbContext);

        dbRef = (this.dbContext.startsWith('hunt-submissions') ? dbRef.child(entry.createdBy || entry.uid) : dbRef).child(entryId);

        if (value == 0) {
          dbRef.child('completed').remove();
          dbRef.child('completedBy').remove();
          this.deleteItem(entryId);
        } else {
          dbRef.child('completed').set(value);
          dbRef.child('completedBy').set(firebase.auth().currentUser.uid);
          if (this.view !== 'view-archived') {
            this.deleteItem(entryId);
          } else {
            this.chunkKeys.indexOf(entryId) > -1 ? (this.chunkList[this.chunkKeys.indexOf(entryId)].completed = value) : null;
          }
        }
      });

      //Exports
      this.addAction('export-data', async () => {
        if (this.chunkKeys && this.chunkKeys.length > 0) {
          var arrData = await this.getArrayData(this.chunkKeys, this.chunkList);
          this.csvExport(arrData);
          this.action = null;
        } else {
          this.$message.create({
            title: 'Error',
            body: 'No data to export',
            buttons: [this.$message.button('OK', 'btn-accent', null)],
            classes: 'amc-modal',
          });
        }
      });
      this.addAction('export-photos', async () => {
        await this.exportFiles('photos');
      });
      this.addAction('export-files', async () => {
        await this.exportFiles('files');
      });
    },
    async setupView(view) {
      this.readyToView = false;
      this.changeView(view);
      switch (view) {
        case 'view-active':
          if (this.context.includes('hunts')) this.setDatabasePath(this.getUserWrittenLocationContext(), 'hunt-submissions/' + this.itemId);
          else this.setDatabasePath(this.getUserWrittenLocationContext(), `${this.context}/${this.itemId}`);
          break;
        case 'view-archived':
          if (this.context.includes('hunts')) this.setDatabasePath(this.getUserWrittenLocationContext(), 'hunt-submissions/' + this.itemId);
          else this.setDatabasePath(this.getUserWrittenLocationContext(), `${this.context}/${this.itemId}`);
          break;
        case 'in-process':
          if (this.context.includes('hunts')) this.setDatabasePath(this.getUserWrittenLocationContext(), 'hunts/' + this.itemId);
          break;
        case 'submitted':
          this.changeView('view-active');
          if (this.context.includes('hunts')) this.setDatabasePath(this.getUserWrittenLocationContext(), 'hunt-submissions/' + this.itemId);
          break;
      }
      await this.loadAll();
      this.onDataPush();
    },

    // Override this function and do filtering in onDataPust to allow code to work for hunts submissions and forms and archived
    chunkQuery(query) {
      return query;
    },
    async onDataPush() {
      switch (this.view) {
        case 'view-archived':
          this.chunkList = this.chunkList.filter((item) => item.completed && item.completed > 0);
          break;
        case 'view-active':
          this.chunkList = this.chunkList.filter((item) => !item.completed);
          break;
      }
      this.chunkList = this.chunkList.sort((a, b) => a.key - b.key);
      this.chunkKeys = this.chunkList.map((item) => item.key);
      this.readyToView = true;
    },
    getUserEntryData: async function () {
      const tempDBContext = this.context == 'feedback' ? 'user-feedback' : this.dbContext.substr(0, this.dbContext.indexOf('/')) || this.dbContext;
      const entryTypes = {
        hunts: 'HuntEntry',
        'custom-forms': 'FormEntry',
        feedback: 'FormEntry',
        orders: 'StoreOrder',
      };
      const entryType = entryTypes[this.context];
      let currentForm = null;
      if (this.context == 'hunts') {
        const formSS = await this.getLocationRef(this.campgroundKey).child('hunts').orderByChild('key').equalTo(this.itemId).once('value');
        formSS.forEach((currentFormSS) => {
          currentForm = currentFormSS.val();
        });
      } else if (this.context == 'feedback') {
        currentForm = {
          formSchema: [
            {
              label: 'Name',
              name: 'name',
              type: 'text',
            },
            {
              label: 'Site Number',
              name: 'siteNumber',
              type: 'label',
            },
            {
              label: 'Check-In Date',
              name: 'startDate',
              type: 'date',
            },
            {
              label: 'Check-Out Date',
              name: 'endDate',
              type: 'date',
            },
            {
              label: 'Cleanliness',
              name: 'cleanliness',
              type: 'starRating',
            },
            {
              label: 'Staff',
              name: 'staff',
              type: 'starRating',
            },
            {
              label: 'Amenities',
              name: 'amenities',
              type: 'starRating',
            },
            {
              label: 'Additional Comments',
              name: 'additionalComments',
              type: 'textarea',
            },
          ],
        };
      } else if (this.itemId) {
        const formSS = await this.getLocationRef(this.campgroundKey).child(tempDBContext).child(this.itemId).once('value');
        currentForm = formSS.val();
      }
      this.userEntryData = {
        section: 'actions',
        context: this.context,
        dbContext: tempDBContext,
        displayType: entryType,
        item: currentForm,
      };
    },
    checkFilters(item) {
      const text = this.filterText.toLowerCase();
      const hasText = Object.keys(item).reduce((acc, key) => {
        if (acc) return acc;
        if (typeof item[key] === 'object' && item[key].value && typeof item[key].value === 'string') {
          return this.isFilterContains(item[key].value, text);
        }
        return false;
      }, false);
      this.isFilterContains(item.title, text) || this.isFilterContains(item.description, text);
      const hasEvent = !this.filterEvent || this.filterEvent === '' || this.filterEvent === item.eventId;
      let isBetweenDates = true;
      if (this.filterStartDate && item.createDate < this.filterStartDate) isBetweenDates = false;
      const filterEndDate = this.filterEndDate ? this.filterEndDate + 86400000 : null;
      if (filterEndDate && item.createDate > filterEndDate) isBetweenDates = false;
      return hasText && hasEvent && isBetweenDates;
    },

    loadChunksFromData(data) {
      console.log('TCL: this.context', this.context);
      if (this.context === 'hunts' && this.view !== 'in-process') {
        const dataVal = data.val() || {};
        console.log('TCL: dataVal', dataVal);
        const reducedVal = Object.keys(dataVal).reduce((acc, x) => {
          const namedList = Object.keys(dataVal[x]).reduce((acc, z) => {
            acc[z] = dataVal[x][z];
            acc[z].createdBy = x;
            acc[z].key = z;
            return acc;
          }, {});
          return { ...acc, ...namedList };
        }, {});
        this.chunkKeys = Object.keys(reducedVal);
        this.chunkList = Object.values(reducedVal);
      } else {
        data.forEach((child) => {
          this.chunkKeys.push(child.key);
          const childData = child.val();
          childData.key = child.key;
          this.chunkList.push(childData);
        });
      }
    },
    postFilterData() {
      if (this.userEntryData?.item?.type === 'rsvp') {
        this.rsvpStats = this.chunkList.reduce(
          (acc, entry) => {
            const isAttending = entry?.['rsvp-attending']?.value === 'Yes';
            const attendingCount = Number(entry?.['rsvp-quantity']?.value) || 1;
            return {
              attendingCount: isAttending ? acc.attendingCount + attendingCount : acc.attendingCount,
              responseCount: acc.responseCount + 1,
              responseYes: isAttending ? acc.responseYes + 1 : acc.responseYes,
            };
          },
          { attendingCount: 0, responseCount: 0, responseYes: 0 }
        );
      }
    },
    deleteSelected() {
      event.stopPropagation();
    },
    //Entries-specific methods
    getArrayData: async function (itemKeys, itemsList) {
      let arrayData = [];
      let labels = [];

      let types = [];
      let ids = [];
      if (this.userEntryData.displayType == `FormEntry`) {
        ids = this.userEntryData.item.formSchema
          .filter((formItem) => !['header', 'label', 'static'].includes(formItem.type))
          .map((formItem) => {
            labels.push(formItem.label);
            types.push(formItem.type);
            return formItem.name;
          });
      } else if (this.userEntryData.displayType == `HuntEntry`) {
        ids = this.userEntryData.item.steps.map((huntItem) => {
          labels.push(huntItem.title);
          types.push(huntItem.type);
          return huntItem.key;
        });
        ids.push('Total Answers', 'Total Correct Answers', 'Total Incorrect Answers');
        labels = [...labels, ...['Total Answers', 'Total Correct', 'Total Incorrect']];
        types = [...types, ...['text', 'text', 'text']];
      } else {
        ids = ['name', 'siteNumber', 'startDate', 'endDate', 'staff', 'cleanliness', 'amenities', 'additionalComments'];
        labels = ['Name', 'Site', 'Start date', 'End Date', 'Staff', 'Cleanliness', 'Amenities', 'Additional Comments'];
        types = ['text', 'text', 'date', 'date', 'text', 'text', 'text', 'text'];
      }

      arrayData = itemKeys.map((itemKey) => {
        const item = itemsList[itemKeys.indexOf(itemKey)];
        const summary = this.dbContext === 'hunts' ? this.composeSummary(this.userEntryData.item, item) : [];
        return [
          item.loggedInUser || 'User Not Loaded Yet',
          item.loggedInEmail || 'Please Try Again',
          ...(this.dbContext !== 'hunts'
            ? [item.communityId, item.createDate ? `${this.getFormattedDate(item.createDate)} ${this.getFormattedTime(item.createDate)}` : 'N/A']
            : []),
          ...ids.map((id, index) => {
            let value = '';
            if (this.dbContext === 'hunts') {
              const formItem = item[id] || {};
              // value will be answer or completed.
              if (formItem['drawable']) {
                value = formItem['drawable'];
              } else if (formItem['answer']) {
                value = formItem['answer'];
              } else {
                value = formItem['completed'] ? 'Completed' : 'Not completed';
              }

              if (id in summary) {
                value = summary[id] || '0';
              }
            } else {
              const formItem = item[id];
              value = formItem && formItem.value ? formItem.value : formItem && typeof formItem !== 'object' ? formItem : '';
              if (types[index] == 'date' && value) {
                value = this.getFormattedDate(value);
              } else if (types[index] == 'signature' && !value) {
                value = 'Signed image';
              }
            }
            return value || '';
          }),
        ];
      });

      if (this.displayType === 'hunts') {
        arrayData.unshift(['Name', 'Email Address', ...labels]);
      } else {
        arrayData.unshift(['Logged In User', 'Logged In Email', 'Community Id', 'Date Submitted', ...labels]);
      }

      return arrayData;
    },
    markAll(value) {
      //const tempDBContext = this.currentDBContext.substring(0, this.currentDBContext.indexOf('/')) || this.currentDBContext;
      let dbRef = firebase.database().ref('resort-navigator').child('user-written').child(this.getCampgroundKey).child(this.dbContext);
      const uid = firebase.auth().currentUser.uid;
      const firebaseUpdates = this.bulkSelected.reduce((acc, key) => {
        if (value == 0) {
          acc[`${key}/completed`] = null;
          acc[`${key}/completedBy`] = null;
        } else {
          acc[`${key}/completed`] = value;
          acc[`${key}/completedBy`] = uid;
        }
        return acc;
      }, {});
    },
    async exportFiles(title) {
      let exportModal;
      try {
        exportModal = this.$message.create({
          title: 'Exporting',
          body: `<div class="row align-items-center" style="padding-left: 24px">
            <div class="col-auto px-0">
              <div class="spinner-border" role="status"></div>
            </div>
            <div class="col">
              <p class="my-4">Exporting ${title}... Please wait</p>
            </div>
          </div>`,
          buttons: [this.$message.button('Cancel', 'btn-accent', null)],
          classes: 'amc-modal',
        });

        let url = `${this.$apihostname}/api/downloads`;
        let storagePath = `user-written/${this.campgroundKey}/hunts/${this.itemId}`;
        if (this.action === 'export-files') {
          //storagePath = `${this.campgroundKey}/forms/completed/${this.itemId}`;
          storagePath = `user-written/${this.campgroundKey}/forms/${this.itemId}`;
          console.log('Export-files path:', storagePath);
        }

        this.action = null;
        const downloadResponse = await axios.post(url, {
          // reading from android code where the images are stored, they are stored in this location.
          storagePath,
        });
        console.log('🚀 ~ file: ListBuilder.vue:1028 ~ setAction:function ~ downloadResponse', downloadResponse);
        const downloadResponseData = downloadResponse.data;
        if (downloadResponseData.err) {
          setTimeout(() => {
            this.$message.hide(exportModal);

            this.$message.create({
              title: 'Error',
              body: downloadResponseData.message,
              buttons: [this.$message.button('OK', 'btn-accent', null)],
              classes: 'amc-modal',
            });
          }, 500);
        } else {
          setTimeout(() => {
            this.$message.hide(exportModal);

            this.downloadUrl = downloadResponseData.downloadUrl;
            this.$message.create({
              title: 'Download',
              body: 'Data was exported successfully. Click Download button to start downloading zip file',
              buttons: [
                this.$message.button('Dismiss', 'btn-secondary', null),
                this.$message.button('Download', 'btn-accent', () => {
                  console.log(`Opening download link: ${this.$hostname}${this.downloadUrl}`);
                  window.open(`${this.$hostname}${this.downloadUrl}`, '_blank', 'noreferrer');
                }),
              ],
              classes: 'amc-modal',
            });
          }, 500);
        }
      } catch (e) {
        console.error(e);
        setTimeout(() => this.$message.hide(exportModal), 1000);
      }
    },
  },
};
</script>
