<template>
  <div :class="itemHasPassedClass()">
    <div v-if="displayDate()" class="list-item text-center">{{ getFormattedDay(parseFloat(item.startDate)) }}</div>
    <div class="list-item" @click="eventDetail">
      <div class="main-text">
        <input
          v-if="bulkSelected"
          v-model="bulkCheck"
          type="checkbox"
          class="bulk-select"
          :checked="bulkSelected.includes(itemId)"
          @click.stop="bulkCheckItem" />
        <div class="item-info">
          <div class="item-title">
            {{ item.title }}
          </div>
          <div class="item-subtitle small">@{{ getLocation(item) }}</div>
        </div>
      </div>
      <div>
        <div v-if="!item.allDay">
          {{ getFormattedTime(parseFloat(item.startDate)) }}
        </div>
        <div v-else>All Day</div>
        <div v-if="item.cost" class="text-end">$</div>
      </div>
      <div style="width: 24px">
        <i v-if="infoTooltip" v-tooltip-left="infoTooltip" class="material-icons">info</i>
      </div>

      <Dropdown>
        <div @click.stop="eventDetail"><span>Edit</span> <i class="material-icons">edit</i></div>
        <div v-if="item.endDate >= Date.now()" @click.stop="createNotification">
          <span>Create Notification</span><i class="material-icons">add_alert</i>
        </div>
        <div v-if="!isRemote" @click.stop="createFlyer"><span>Create Flyer</span><i class="material-icons">post_add</i></div>
        <div v-if="item.rsvpRequest" @click.stop="openRSVP"><span>View RSVP</span><i class="material-icons">person_add</i></div>
        <div v-if="item.recurrenceId" @click.stop="filterRecurrences"><span>Filter Recurrences</span><i class="material-icons">manage_search</i></div>
        <div @click.stop="duplicateItem"><span>Duplicate</span><i class="material-icons">content_copy</i></div>
        <div @click.stop="deleteItem"><span>Delete</span><i class="material-icons">delete</i></div>
      </Dropdown>
    </div>
    <b-modal
      v-if="isDeleteOpen"
      :id="`modal-${itemId}`"
      :title="deleteModalInfo.title"
      classes="amc-modal"
      :buttons="deleteModalInfo.buttons"
      @show="initializeContexts()"
      @hidden="isDeleteOpen = false"
      @ready="ready">
      <template #body>
        <div v-if="initialContexts.length <= 1">
          <p class="my-4">{{ deleteModalInfo.body }}</p>
        </div>
        <div v-else>
          <p class="my-4">{{ deleteModalInfo.body }}</p>
          <div class="mb-3">
            <div v-for="(context, index) in initialContexts" :key="context.value" class="form-check">
              <input :id="`context-${index}`" v-model="selectedContexts" :value="context.value" class="form-check-input" type="checkbox" />
              <label class="form-check-label" :for="`context-${index}`">{{ context.text }}</label>
            </div>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Dropdown from '@/components/common/Dropdown.vue';
import BModal from '@/components/common/BModal.vue';

import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import 'firebase/compat/functions';

export default {
  components: {
    Dropdown,
    BModal,
  },
  props: ['item', 'itemId', 'locations', 'dbContext', 'view', 'prevItemId', 'prevItem', 'isArchived', 'bulkSelected'],
  data() {
    return {
      bulkCheck: false,
      deleteModal: null,
      isDeleteOpen: false,
      initialContexts: [],
      selectedContexts: [],
      infoTooltip: null,
      isRemote: false,
    };
  },
  async mounted() {
    this.isRemote = ['remoteCalendar', 'remoteCalendarNoKey'].includes(this.$route.name);
    this.detailRouteType = this.isArchived ? 'eventsArchived' : 'events';
    await this.setTooltip();
  },
  methods: {
    ready(modal) {
      this.deleteModal = modal;
    },
    getLocation: function (item) {
      if (!item.resortLocationId) {
        return item.location;
      }
      return this.locations[item.resortLocationId] ? this.locations[item.resortLocationId].title : '';
    },
    eventDetail: function () {
      if (this.isRemote) this.$router.push({ name: 'remoteEventEdit', params: { dbContext: this.dbContext, itemId: this.itemId } });
      else this.$router.push({ name: 'detailEdit', params: { type: this.detailRouteType, dbContext: this.dbContext, itemId: this.itemId } });
    },
    displayDate: function () {
      return !this.prevItem || this.getFormattedDay(parseFloat(this.item.startDate)) !== this.getFormattedDay(parseFloat(this.prevItem.startDate));
    },
    openRSVP: function () {
      this.$router.push({
        name: 'scrollerEntries',
        params: { context: 'custom-forms', action: 'view', itemId: this.item.rsvpFormKey },
        query: { eventId: this.itemId },
      });
    },
    createNotification: function () {
      const newItem = {
        //If this title or description format/template are changed, update them in Event as well (updateNotifications).
        title: `Reminder: ${this.item.title}${this.getLocation(this.item) ? ' @' + this.getLocation(this.item) : ''}`,
        //Strips HTML tags and special characters from description.
        description: this.item.description
          ?.replace(/<br>/g, '\n\n')
          .replace(/<[^>]+>/g, '')
          .replace(/&[^\s]*;/g, ''),
        userGroup: 'currentGuest',
        location: this.getLocation(this.item),
        startDate: this.item.startDate,
        endDate: this.item.endDate,
        isFromEvent: true,
        eventId: this.itemId,
      };
      this.pushToNotification(newItem);
    },
    createFlyer() {
      const detailPath = `${this.dbContext}/${this.itemId}`;
      this.$store.commit('updateParams', { newItem: { ...this.item, detailPath }, location: this.getLocation(this.item) });
      this.$router.push({ name: 'createFlyer', params: { action: 'event' } });
    },
    filterRecurrences() {
      console.log('TCL: filterRecurrences -> this.item.recurrenceId', this.item.recurrenceId);

      this.$emit('action', 'filter-recurring', this.item.recurrenceId);
    },

    async initializeContexts() {
      if (this.selectedContexts.length == 0) this.selectedContexts.push(`${this.getCampgroundKey}/amc-events/calendars/${this.dbContext}`);
      if (this.item.contexts?.length > 1) {
        let calendarTitles = {};
        const cLocations = (await this.getAllCampgroundLocationsRef().once('value')).val();
        this.initialContexts = [];
        for (const currentContext of this.item.contexts) {
          const [cLocationKey, _1, _2, calendarId] = currentContext.split('/');
          if (cLocations[cLocationKey]) {
            const calendarTitleKey = `${cLocationKey}-${calendarId}`;
            if (!calendarTitles[calendarTitleKey]) {
              const calendarConfig = (await this.getContextRef(`amc-events/config/`, cLocationKey).once('value')).val();
              calendarTitles = Object.keys(calendarConfig).reduce((acc, key) => {
                const currentCalendarTitleKey = `${cLocationKey}-${key}`;
                acc[currentCalendarTitleKey] = calendarConfig[key].title;
                return acc;
              }, calendarTitles);
            }
            const text = `${cLocations[cLocationKey].title} -> ${calendarTitles[calendarTitleKey]}`;
            this.initialContexts.push({ text: text, value: currentContext });
          }
        }
      }
    },

    deleteItem: async function () {
      /**/

      await this.initializeContexts();

      if (this.item.recurrenceId) {
        this.deleteModalInfo = {
          title: 'Delete Recurrence',
          body:
            this.initialContexts.length > 1
              ? 'Which calendars do you want to delete this event or all events in this recurrence from?'
              : 'Do you want to delete only this event or all events in this recurrence?',
          buttons: [
            this.$message.button('Cancel', 'btn-secondary', null),
            this.$message.button('Delete Recurrence', 'btn-danger', this.deleteRecurringItem),
            this.$message.button('Delete Event', 'btn-danger', this.deleteSingleItem),
          ],
        };
      } else if (this.item.id) {
        this.deleteModalInfo = {
          title: 'Delete Imported Event',
          body: 'Would you like to delete this event and no longer import, or delete it and allow future imports?',
          buttons: [
            this.$message.button('Cancel', 'btn-secondary', null),
            this.$message.button('Delete Forever', 'btn-danger', () => {
              this.deleteSingleItem();
              this.$emit('action', 'ignore-import', this.item.id, this.item.calendarId);
            }),
            this.$message.button('Delete, Allow Future Imports', 'btn-danger', this.deleteSingleItem),
          ],
        };
      } else {
        this.deleteModalInfo = {
          title: 'Delete Event',
          body:
            this.initialContexts.length > 1
              ? 'Which calendars would you like to delete this event from?'
              : 'Are you sure you want to delete this event?',
          buttons: [this.$message.button('Cancel', 'btn-secondary', null), this.$message.button('Delete', 'btn-danger', this.deleteSingleItem)],
        };
      }
      this.isDeleteOpen = true;

      this.$nextTick(() => this.deleteModal.show());
      this.componentId = 'delete-item';
    },
    getRecurrenceDataFromFB: async function () {
      return await this.getContextRef(`amc-events/recurrenceInfo`).child(this.item.recurrenceId).once('value');
    },
    deleteSingleItem() {
      const originalContexts = [...(this.item.contexts || this.dbContext)];
      this.item.contexts = this.item.contexts.filter((x) => !this.selectedContexts.includes(x));
      const firebaseUpdates = originalContexts.reduce((accumulator, currentContext) => {
        if (this.selectedContexts.includes(currentContext)) {
          accumulator[`${currentContext}/events/${this.itemId}`] = null;
          const cLocation = currentContext.split('/')[0];
          accumulator[`${cLocation}/amc-events/recurrenceInfo/${this.item.recurrenceId}/eventKeys/${this.itemId}`] = null;
          accumulator[`${cLocation}/amc-events/recurrenceInfo/${this.item.recurrenceId}/editedEvents/${this.itemId}`] = null;
        } else {
          accumulator[`${currentContext}/events/${this.itemId}`] = this.item;
        }
        return accumulator;
      }, {});
      if (!this.isRemote) {
        this.getLocationDataPath(this.isArchived).update(firebaseUpdates);
      } else {
        const remoteHTTPS = firebase.functions().httpsCallable('remoteBusinessUpdate');
        remoteHTTPS({ uuid: this.$route.params.uuid, action: 'updateEvents', updates: firebaseUpdates });
      }

      const currentContext = `${this.getCampgroundKey}/amc-events/calendars/${this.dbContext}`;

      if (this.selectedContexts.includes(currentContext)) {
        this.$emit('deleteItem', this.itemId);
      }
    },
    getRecurrancesToRemove(recurrenceEventKeysArray, eventKeys) {
      const currentTime = Date.now();
      if (this.isArchived) {
        return recurrenceEventKeysArray.filter((eventKey) => Number(eventKeys[eventKey].startDate) < currentTime);
      }
      return recurrenceEventKeysArray;
    },
    async deleteRecurringItem() {
      const originalContexts = [...this.item.contexts];
      this.item.contexts = this.item.contexts.filter((x) => !this.selectedContexts.includes(x));
      const recurrenceInfo = (await this.getRecurrenceDataFromFB()).val();
      const recurrenceEventKeysArray = Object.keys(recurrenceInfo.eventKeys);

      const removedItems = this.getRecurrancesToRemove(recurrenceEventKeysArray, recurrenceInfo.eventKeys);
      const removeEntireRecurrence = recurrenceEventKeysArray.length == removedItems.length;
      const firebaseUpdates = recurrenceEventKeysArray.reduce((accumulator, recurrenceEventKey) => {
        for (const currentContext of originalContexts) {
          if (this.selectedContexts.includes(currentContext)) {
            accumulator[`${currentContext}/events/${recurrenceEventKey}`] = null;
            if (!removeEntireRecurrence && this.selectedContexts.length < this.item.contexts.length) {
              const cLocation = currentContext.split('/')[0];
              accumulator[`${cLocation}/amc-events/recurrenceInfo${this.item.recurrenceId}/eventKeys/${recurrenceEventKey}`] = null;
              for (const currentCLocation in recurrenceInfo.sections) {
                accumulator[`${currentCLocation}/amc-events/${this.item.recurrenceId}/editedEvents/${recurrenceEventKey}`] = null;
              }
              accumulator[`${cLocation}/amc-events/${this.item.recurrenceId}/editedEvents/${recurrenceEventKey}`] = null;
            }
          } else {
            accumulator[`${currentContext}/events/${recurrenceEventKey}/contexts`] = this.item.contexts;
          }
        }
        return accumulator;
      }, {});

      for (const currentCLocation in recurrenceInfo.sections) {
        if (removeEntireRecurrence && this.selectedContexts.length < this.item.contexts.length) {
          firebaseUpdates[`${currentCLocation}/amc-events/recurrenceInfo/${this.item.recurrenceId}`] = null;
        } else {
          firebaseUpdates[`${currentCLocation}/amc-events/recurrenceInfo/${this.item.recurrenceId}/originalItem/contexts`] = this.item.contexts;
        }
      }

      this.getLocationDataPath(this.isArchived).update(firebaseUpdates);
      const currentContext = `${this.getCampgroundKey}/amc-events/calendars/${this.dbContext}`;

      if (this.selectedContexts.includes(currentContext)) {
        this.$emit('deleteItem', this.itemId);
      }
    },
    duplicateItem: function () {
      this.$router.push({ name: 'detailDuplicate', params: { type: this.detailRouteType, dbContext: this.dbContext, itemId: this.itemId } });
    },
    itemHasPassedClass: function () {
      return this.item.endDate < Date.now() && this.view !== 'view-archived' ? 'event-passed' : 'event-current';
    },
    bulkCheckItem: function () {
      //this.bulkCheck = !this.bulkCheck;
      this.$emit('bulkCheckItem', this.itemId, this.bulkCheck);
    },
    async setTooltip() {
      const infoArray = [];
      if (this.item.recurrenceId) {
        infoArray.push('<li>Recurring event</li>');

        const editedEvent = (
          await this.getLocationRef()
            .child('amc-events')
            .child('recurrenceInfo')
            .child(this.item.recurrenceId)
            .child('editedEvents')
            .child(this.itemId)
            .once('value')
        ).val();
        if (editedEvent) infoArray.push('<li>Modified from the original recurrence</li>');
      }

      if (this.item.rsvpRequest) infoArray.push('<li>RSVP Requests</li>');

      if (this.item.id) infoArray.push('<li>Imported Event</li>');

      if (this.item.huntKey) infoArray.push('<li>Scavenger hunt</li>');

      this.infoTooltip = infoArray.length > 0 ? `<ul>${infoArray.join('')}</ul>` : '';
    },
  },
};
</script>

<style>
.main-text {
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.main-text .item-info {
  display: flex;
  flex-direction: column;
}
input[type='checkbox'].bulk-select {
  width: 20px !important;
  min-width: 20px;
}
.item-subtitle {
  white-space: initial;
}
.event-passed {
  background-color: red;
}
.show {
  display: inline !important;
}
</style>
