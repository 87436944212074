<template>
  <div class="two-column">
    <div id="center-column" class="infinite-scroll" @scroll="onScroll">
      <div id="item-header" :style="getHeaderStyles()">
        <iframe
          v-if="headerInfo.videoURL"
          width="490"
          height="261"
          :src="`https://www.youtube.com/embed/${headerInfo.videoURL}`"
          title="YouTube video player"
          frameborder="0"
          allow="mute; autoplay; allowfullscreen"></iframe>
        <div
          v-if="headerInfo.images && headerInfo.images.length > 0"
          id="listBuilderCarousel"
          class="carousel slide"
          data-bs-ride="carousel"
          :data-bs-interval="headerInfo.delaySeconds * 1000 || 5000">
          <div class="carousel-inner">
            <div class="carousel-item" v-for="(src, index) in this.headerInfo.images" :class="{ active: index === 0 }" :key="index">
              <img :src="src.imageURL" class="d-block w-100" :alt="'Slide ' + (index + 1)" />
              <div class="carousel-caption d-none d-md-block">
                <p>{{ src.caption }}</p>
              </div>
            </div>
          </div>
          <ol class="carousel-indicators" style="list-style-type: none">
            <li
              v-for="(src, index) in headerInfo.images"
              :key="index"
              data-bs-target="#listBuilderCarousel"
              :data-bs-slide-to="index"
              :class="{ active: index === 0 }" />
          </ol>
          <button
            v-if="headerInfo.images && headerInfo.images.length > 1"
            id="carousel-control-prev"
            class="carousel-control-prev"
            type="button"
            data-bs-target="#listBuilderCarousel"
            data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button
            v-if="headerInfo.images && headerInfo.images.length > 1"
            id="carousel-control-next"
            class="carousel-control-next"
            type="button"
            data-bs-target="#listBuilderCarousel"
            data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
        <h1 v-show="!eventConfig?.['header-info']?.hideTitle">{{ eventConfig.title }}</h1>
      </div>

      <div style="padding: 8px 16px; border-bottom: 1px solid black">
        <div style="display: flex; align-items: baseline">
          <input
            type="checkbox"
            id="bulk-select-checkbox"
            class="bulk-select"
            v-model="bulkCheckAll"
            :indeterminate="bulkSelected.length !== 0 && bulkSelected.length !== this.chunkKeys.length" />

          <div class="simple-title list-item" style="line-height: 40px" data-bs-toggle="collapse" :data-bs-target="'#filter'">
            <!-- Set label to :for bulk-select -->
            <label for="bulk-select-checkbox" data-bs-toggle="collapse" :data-bs-target="'#placebo-filter'" style="width: 65px">Select All</label>
            <button
              v-if="filterReady?.any()"
              type="button"
              class="btn btn-secondary my-0 p-1 w-100"
              data-bs-toggle="collapse"
              :data-bs-target="'#placebo-filter'"
              @click.prevent="clearFilters">
              Clear Filters
            </button>
            <button
              v-if="bulkCheckAll || bulkSelected.length > 0"
              type="button"
              class="btn btn-secondary my-0 p-1 w-100"
              data-bs-toggle="collapse"
              :data-bs-target="'#placebo-filter'"
              @click="deleteSelected">
              Delete Selected
            </button>
            <i class="material-icons expand"></i>
          </div>
        </div>
        <FilterList
          @filterText="(val) => (filterText = val)"
          @filterDate="
            (start, end) => {
              filterStartDate = start;
              filterEndDate = end;
            }
          "
          :external-filters="recurrenceId ? true : false"
          @ready="(ready) => (filterReady = ready)" />
      </div>

      <ul
        :id="displayType == 'categorygrid' ? 'grid-container' : 'items'"
        :class="{ 'hide-actions': action }"
        v-for="(element, index) in chunkKeys"
        :key="index">
        <li :class="displayType == 'categorygrid' ? '' : 'item'">
          <component
            :is="centerComponent"
            :itemId="element"
            :item="chunkList[index]"
            :prevItemId="chunkKeys[index - 1]"
            :prevItem="chunkList[index - 1]"
            :locations="locations"
            :listIcons="listIcons"
            :dbContext="dbContext"
            :view="view"
            :is-archived="isArchived"
            :displayType="displayType"
            :bulk-selected="bulkSelected"
            @deleteItem="deleteItem"
            @action="setAction"
            @editItem="editItem"
            @bulkCheckItem="bulkCheckItem" />
        </li>
      </ul>
    </div>
    <div id="right-column" class="p-2">
      <component
        :is="rightColumnComponent"
        v-bind="rightColumnProps"
        @action="setAction"
        :key="rightColumnKey"
        :rightColumnKey="rightColumnKey"
        :action="action"
        :is-archived="isArchived"
        @header-info-update="headerInfoUpdate" />
    </div>

    <Overlay text="Loading events" :showProp="showOverlay" />
  </div>
</template>

<script>
import InfiniteScrollMixin from '@/components/mixins/InfiniteScrollMixin.js';
import { typeMap, newObjectMap } from '@/json/listBuilderData.json';
import ButtonNavigation from '@/components/ButtonNavigation.vue';

import HeaderImages from '@/components/forms/HeaderImages.vue';
import EventItems from '@/components/listitems/EventItems.vue';
import EventConfig from '@/components/forms/EventConfig.vue';
import FilterList from '@/components/inputfields/FilterList.vue';
import Overlay from '@/components/common/Overlay.vue';
import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';

export default {
  components: {
    ButtonNavigation,
    EventItems,
    HeaderImages,
    EventConfig,
    FilterList,
    Overlay,
  },
  mixins: [InfiniteScrollMixin],
  watch: {
    '$route.params': {
      async handler(newVal, oldVal) {
        console.log('route params changed');

        if (newVal && oldVal && newVal?.detailId !== oldVal?.detailId) {
          this.clearChunks();
          await this.setConfig();
          await this.resetData();
        }
      },
    },
  },
  data() {
    return {
      initialChunkSize: 50,
      subsequentChunkSize: 20,
      type: 'events',
      displayType: 'events',
      context: 'events',
      isArchived: false,
      eventConfig: { 'header-info': [] },
      headerInfo: {},
      showTitle: true,
      locations: [],
      listIcons: [],
      recurrenceId: null,
      remoteHTTPS: null,
      remoteType: null,
      filterReady: null,
    };
  },
  async created() {
    this.setRouteProperties();
    this.addActions();
  },
  async mounted() {
    this.campgroundKey = this.getCampgroundKey;
    this.isRemote = ['remoteCalendar', 'remoteCalendarNoKey'].includes(this.$route?.name);

    await this.setConfig();
    await this.getLocations();
    await this.resetData();
    if (!this.action) await this.setAction();

    //Fix for bootstrap not automatically starting carousel.
    setTimeout(() => {
      if (this.headerInfo.images && this.headerInfo.images.length > 1) {
        const prev = document.getElementById('carousel-control-prev');
        const next = document.getElementById('carousel-control-next');
        next?.click();
        prev?.click();
      }
    }, 1000);
  },
  methods: {
    async setRemoteProperties() {
      if (this.isRemote) {
        this.showOverlay = true;

        // ----- retrieving remote info
        this.remoteHTTPS = firebase.functions().httpsCallable('remoteUpdate');
        this.remoteType = this.$route?.name == 'remoteCalendar' ? 'detail' : 'uid'; //remoteCalendar / remoteCalendarNoKey

        const detailId = this.$route.params?.detailId;
        const uuid = this.$route.params?.uuid || firebase.auth().currentUser?.uid;

        const data = (await this.remoteHTTPS({ action: 'getRemoteInfo', type: this.remoteType, detailId, uuid })).data;
        console.log('remote info', this.remoteType, detailId, uuid, data);

        if (this.remoteType === 'detail') this.remoteInfo = data[uuid];
        else if (this.remoteType === 'uid') {
          for (let key in data) {
            console.log('key', key, data[key]);

            if (data[key]?.type === 'calendar') this.remoteInfo = data[key];
          }
        }
        this.remoteInfo.uuid = uuid;

        // ----- setting properties based off of remote info
        this.eventConfig = (
          await this.remoteHTTPS({ context: this.remoteInfo.origContext, calendarId: this.remoteInfo.contexts[0], action: 'getEventConfig' })
        ).data;
        console.log('remote config', this.eventConfig);
        this.headerInfo = this.eventConfig['header-info'] || [];

        this.$store.commit('updateRemoteInfo', this.remoteInfo);
        const communityInfo = (await this.remoteHTTPS({ action: 'getCommunity', context: this.remoteInfo.origContext })).data;
        await this.setCampgroundLocationData(this.remoteInfo.origContext, communityInfo);
        this.campgroundKey = this.remoteInfo.origContext;

        this.dbContext = this.remoteInfo.contexts[0];
        this.$route.params.context = this.dbContext;

        this.view = 'view-active';
        this.showOverlay = false;
      }
    },
    async setConfig() {
      if (this.isRemote) {
        await this.setRemoteProperties();
      } else {
        const config = await this.getLocationRef(this.campgroundKey).child('amc-events').child('config').child(this.dbContext).once('value');
        this.eventConfig = config.val() || { 'header-info': [] };
        this.headerInfo = this.eventConfig['header-info'] || [];
      }
    },

    addActions() {
      this.addAction('submit', () => {
        this.action = null;
        this.clearChunks();
        this.resetData();
      });
      this.addAction('cancel', () => {
        this.action = null;
        if (this.rightColumnItem) {
          this.rightColumnItem = {};
          this.deleteItem(this.rightColumnKey);
        }
        this.actions['default']();
      });
      this.addAction('edit', () => {
        this.rightColumnItem = this.getItemByKey(this.rightColumnKey);
        this.rightColumnProps = {
          dbContext: this.dbContext,
          item: this.rightColumnItem,
          rightColumnKey: this.rightColumnKey || '',
          action: this.action,
        };

        if (this.displayType == 'events') this.rightColumnProps.itemsList = this.chunkList;
        if (newObjectMap[this.displayType] && newObjectMap[this.displayType].detailComponent) {
          this.rightColumnComponent = newObjectMap[this.displayType].detailComponent;
          console.log('rightColumnComponent', this.rightColumnComponent);
        }
      });
      this.addAction('duplicate', () => {
        const duplicateKey = this.params[1];
        let newObject = { ...this.getItemByKey(duplicateKey) };

        this.$delete(newObject, 'effectiveTime');
        this.$delete(newObject, 'endTime');
        this.$delete(newObject, 'deliveryTime');
        this.$delete(newObject, 'recurrenceId');
        this.$delete(newObject, 'recurrenceInfo');

        this.rightColumnKey = this.databasePath.push().key;
        this.chunkKeys.unshift(this.rightColumnKey);
        this.chunkList.unshift(newObject);
        console.log('chunk data', this.chunkList, this.chunkKeys);

        //Fallthrough. Loads the object into the right column for editing.
        this.setAction('edit');
        this.$nextTick(() => {
          this.action = 'add-button';
        });
      });
      this.addAction('add-button', () => {
        if (newObjectMap[this.displayType] && newObjectMap[this.displayType].newObject) {
          this.rightColumnItem = JSON.parse(JSON.stringify(newObjectMap[this.displayType].newObject));
        }

        this.rightColumnKey = this.databasePath.push().key;
        this.chunkKeys.unshift(this.rightColumnKey);
        this.chunkList.unshift(this.rightColumnItem);

        this.actions['edit']();
        console.log('item chunkList', this.chunkList[this.rightColumnKey], this.chunkList, this.chunkKeys);
      });

      this.addAction('header-images', () => {
        this.rightColumnProps = {
          section: 'actions',
          context: this.action,
          dbContext: this.headerContextMap[this.dbContext] || this.dbContext,
          newPath: `amc-events/config/${this.dbContext}/header-info`,
          visible: true,
          displayType: 'HeaderImages',
          tabs: this.tabs,
          title: this.eventConfig.title,
        };
        this.rightColumnComponent = 'HeaderImages';
        this.action = null;
      });
      this.addAction('config.events', () => {
        this.rightColumnProps = {
          section: 'actions',
          context: 'config/events',
          dbContext: 'config/events',
          displayType: 'EventConfig',
          locations: this.locations,
          tabs: this.tabs,
        };
        this.rightColumnComponent = 'EventConfig';
      });

      this.addAction('view-archived', async () => {
        this.changeView('view-archived');

        this.databasePath = await this.getLocationRef(this.campgroundKey, true)
          .child('amc-events')
          .child('calendars')
          .child(this.dbContext)
          .child('events');
        this.displayType = 'events-archive';

        await this.nextChunk();
      });
      this.addAction('view-active', async () => {
        this.changeView('view-active');

        if (this.isRemote) {
          this.loadEventsRemote();
        } else {
          this.databasePath = await this.getLocationRef(this.campgroundKey)
            .child('amc-events')
            .child('calendars')
            .child(this.dbContext)
            .child('events');
          this.displayType = 'events';

          await this.nextChunk();
        }
      });
      this.addAction('filter-recurring', async (args) => {
        this.recurrenceId = args[1];
        await this.loadOnSelectAll();
        this.filterData();
        this.action = null;
      });

      this.addAction('ignore-import', async (args) => {
        const importId = args[1];
        const calendarId = args[2];
        console.log(`Ignoring '${importId}' in '${calendarId}' calendar.`);

        const dbRef = this.getLocationRef(this.campgroundKey).child('amc-events').child('config').child(this.dbContext);
        const importRef = dbRef.child('import');

        importRef.once('value').then((snapshot) => {
          const data = snapshot.val();
          if (data) {
            for (const key in data) {
              const item = data[key];
              if (item.calendarId === calendarId) {
                const array = item.ignored || [];
                array.push(importId);
                importRef.child(key).child('ignored').set(array);
              }
            }
          }
        });

        this.action = null;
      });
    },
    setLastKey(result) {
      this.lastLoadedKey =
        result.list.length > 0 ? (this.view == 'view-archived' ? result.list[0].startDate : result.list[result.list.length - 1].startDate) : null;
    },
    chunkQuery(query) {
      query = query.orderByChild('startDate');
      if (this.view == 'view-active') {
        if (this.lastLoadedKey) query = query.startAt(this.lastLoadedKey);
        query = query.limitToFirst(this.chunkSize);
      }
      if (this.view == 'view-archived') {
        if (this.lastLoadedKey) query = query.endAt(this.lastLoadedKey);
        query = query.limitToLast(this.chunkSize);
      }
      return query;
    },

    async getLocations() {
      if (this.isRemote) {
        const data = (
          await this.remoteHTTPS({
            uuid: this.$route.params.uuid,
            action: 'getLocations',
            context: this.campgroundKey,
          })
        ).data;
        console.log('location data', data);
        this.locations = data;
      } else {
        this.locationDataRef = this.getLocationRef(this.campgroundKey);
        this.locations = (await this.locationDataRef.child('locations').once('value')).val() || {};
      }
    },
    headerInfoUpdate(item) {
      this.headerInfo = item;
      console.log('headerInfoUpdate', item);
    },
    getHeaderStyles: function () {
      if (this.headerInfo) {
        const image = this.headerInfo.images && this.headerInfo.images.length > 0 ? this.headerInfo.images[0].imageURL || null : null;
        if (image) {
          return `height:261px;background-image:url("${image}");`;
        } else if (this.headerInfo.videoURL) {
          return `height:261px;`;
        }
      }
      return `${this.getBackgroundColorStyle}height:50px;`;
    },

    async clearFilters() {
      this.filterReady.clear();
      this.recurrenceId = null;
      await this.loadOnSelectAll();
      this.filterData();
    },
    checkFilters(item) {
      const results = [];
      //Text filters
      if (this.filterText) {
        const textResults = [];
        const text = this.filterText.toLowerCase();
        textResults.push(this.isFilterContains(item.title, text));
        textResults.push(this.isFilterContains(item.description, text));
        textResults.push(this.isFilterContains(this.locations[item.resortLocationId]?.title, text));

        //Add true to result if textResults has any true values.
        results.push(textResults.some((result) => result));
      }

      //Start/end date filters
      if (this.filterStartDate) {
        if (item.endDate > this.filterStartDate) results.push(true);
        else results.push(false);
      }
      if (this.filterEndDate) {
        if (item.endDate + item.endDateDuration < this.filterEndDate) results.push(true);
        else results.push(false);
      }
      if (this.recurrenceId) results.push(item.recurrenceId === this.recurrenceId);

      return results.every((result) => result);
    },
    deleteSelected() {
      this.$message.create({
        title: 'Delete Item',
        body: 'Are you certain you want to delete all selected items?',
        buttons: [
          this.$message.button('Cancel', 'btn-secondary'),
          this.$message.button('Delete', 'btn-danger', async () => {
            const currentTime = Date.now();
            const removalPaths = this.bulkSelected.reduce((acc, itemId) => {
              acc[`${this.dbContext}/events/${itemId}`] = null;
              /*
              if (this.isArchived) {
                acc[`${this.dbContext}/${itemId}`] = null;
              } else {
                acc[`${this.dbContext}/${itemId}/endDate`] = currentTime;
              }
              */
              return acc;
            }, {});
            this.getContextRef('amc-events/calendars', null, this.isArchived).update(removalPaths);
            this.deleteItem(this.bulkSelected);
            this.bulkSelected = [];
            this.bulkCheckAll = false;
          }),
        ],
      });
    },

    async loadEventsRemote() {
      const data = (
        await this.remoteHTTPS({
          action: 'getEvents',
          context: this.campgroundKey,
          calendarId: this.dbContext,
        })
      ).data;
      console.log('data', data);

      //Load all events through cloud function
      if (data.calendar) {
        this.chunkKeys = [];
        this.chunkList = [];
        const sort = [];
        const calendar = data.calendar;
        for (let key in calendar) {
          sort.push({ key: key, val: calendar[key], date: calendar[key].endDate || 0 });
        }
        //Sort by endDate in sort
        sort.sort((a, b) => a.date - b.date);
        sort.forEach((i) => {
          this.chunkKeys.push(i.key);
          this.chunkList.push(i.val);
        });

        this.chunksLoaded = true;
        this.$forceUpdate();
      }
    },

    async resetData() {
      // this.setDatabasePath(await this.getLocationRef(this.campgroundKey).child('amc-events').child('calendars'), `${this.dbContext}`);
      this.emptyComponent = typeMap[this.type][0].emptyComponent;
      this.emptyMessage = typeMap[this.type][0].emptyMessage;

      await this.setAction();

      //Swap views from route param
      if (['view-active', 'active'].includes(this.view)) await this.setAction('view-active');
      else if (['view-archived', 'archive'].includes(this.view)) await this.setAction('view-archived');
    },
    //Override to sort properly
    loadChunksFromData(data) {
      console.log('data', data);

      data.forEach((child) => {
        this.chunkKeys.push(child.key);
        this.chunkList.push(child.val());
      });
      //Sort chunkKeys/chunkList by chunkList[key].startDate.

      this.chunkList.sort((a, b) =>
        ['view-archived', 'archive'].includes(this.view)
          ? new Date(b.startDate) - new Date(a.startDate)
          : new Date(a.startDate) - new Date(b.startDate)
      );
    },
  },
};
</script>
