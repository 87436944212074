<template>
  <div :class="getItemClass" @click.stop="editItem">
    <div class="main-text">
      <input
        v-if="bulkSelected"
        v-model="bulkCheck"
        type="checkbox"
        class="bulk-select"
        :checked="bulkSelected.includes(itemId)"
        @click.stop="bulkCheckItem" />
      <div class="item-title">
        <div>{{ item.orderId ? `#${item.orderId} - ` : '' }}{{ getEntryTime }}</div>
        <div>
          <div class="spinner-border text-primary" role="status" v-if="spinnerActive">
            <span class="visually-hidden">Loading...</span>
          </div>
          {{ getUserId }}
        </div>
      </div>
    </div>
    <div v-if="incomplete || unconfirmed" class="confirmation-section">
      <div v-if="unconfirmed" class="not-confirmed">{{ needsConfirmed - unconfirmed }}/{{ needsConfirmed }} confirmed.</div>
      <div v-if="incomplete" class="not-confirmed">{{ item.cartItems.length - incomplete }}/{{ item.cartItems.length }} complete.</div>
    </div>
    <i v-if="item.completed > 0" class="material-icons show" @click.stop="markItem(completedValues.open)" v-tooltip-bottom="'Reopen'">unarchive</i>
    <i class="material-icons show" @click.stop="markItem(completedValues.completed)" v-tooltip-bottom="'Completed'">done</i>
    <i class="material-icons show" @click.stop="markItem(completedValues.denied)" v-tooltip-bottom="getTitle">clear</i>
  </div>
</template>

<script>
import 'firebase/compat/auth';
import 'firebase/compat/database';
import axios from 'axios';
import moment from 'moment';

export default {
  props: ['item', 'itemId', 'dbContext', 'displayType', 'inProcess', 'bulkSelected'],
  data() {
    return {
      completedValues: {
        completed: 2,
        open: 0,
        denied: 1,
      },
      spinnerActive: false,
      userName: 'N/A',
      masterIds: null,
      currentDBContext: '',
      unconfirmed: 0,
      needsConfirmed: 0,
      incomplete: 0,
      origArchived: false,
      bulkCheck: false,
    };
  },
  computed: {
    getItemClass: function () {
      const isOpen = this.item.completed == this.completedValues.open || !this.item.completed;

      const classes = {
        'list-item': true,
        'form-completed': this.item.completed == this.completedValues.completed,
        'form-denied': this.item.completed == this.completedValues.denied,
        'hide-item': this.origArchived === isOpen,
        'background-visibility':
          this.displayType == 'forms' &&
          (this.item.completed == this.completedValues.completed || this.item.completed == this.completedValues.denied),
      };
      return classes;
    },
    getEntryTime: function () {
      const entryTime = this.item.orderTime || this.item.createDate || (!this.inProcess && !isNaN(this.itemId) ? Number(this.itemId) : null);
      return !entryTime ? '' : moment.tz(entryTime, this.getTimeZone).format('MM/DD/YYYY h:mm a');
    },
    getOrderNumber: function () {
      return this.dbContext == `orders` ? `#${this.itemId} - ` : '';
    },
    getTitle: function () {
      return this.dbContext == `orders` ? `Cancelled` : `Denied`;
    },
    getUserId() {
      if (this.masterIds) return `${this.masterIds} (${this.userName})`;
      return this.userName;
    },
  },
  watch: {
    item: {
      handler(newValue, oldValue) {
        this.initialSetup();
      },
    },
  },
  async created() {
    this.initialSetup();
  },
  methods: {
    initialSetup: async function () {
      this.origArchived = this.item.completed > 0;
      this.currentDBContext = this.dbContext == 'feedback' ? 'user-feedback' : this.dbContext;
      this.getUserName();
      /*this.needsConfirmed = 0;
    this.incomplete = 0;
    */
      this.unconfirmed = !this.item.cartItems
        ? 0
        : this.item.cartItems.reduce((unconfirmedCount, cartItem) => {
            this.needsConfirmed = this.needsConfirmed + cartItem.deliveryTime ? 1 : 0;
            this.incomplete = this.incomplete + (!cartItem.completed || cartItem.completed <= 0) ? 1 : 0;
            return unconfirmedCount + (cartItem.deliveryTime && cartItem.confirmed != true) ? 1 : 0;
          }, 0);
      if (this.dbContext.startsWith('custom-forms')) {
        await this.getSchema();
        this.getMasterIDs();
      }
    },
    getUserName: function () {
      const userId = this.item.createdBy || this.item.uid || this.itemId;
      try {
        /*const dbRef = firebase.database().ref("resort-navigator").child("users").child(userId).child("name");
                    dbRef.once('value').then(userSnapShot => {
                        const userName = userSnapShot.val();
                        if(userName){
                            this.item.loggedInUser = userName;
                            this.item.loggedInEmail = userName;
                            console.log('this.item', this.item)
                            this.userName = userName;
                        } else {
                            */
        this.userName = '';
        this.spinnerActive = true;
        const url = `${this.$apihostname}/api/users?campgroundId=${this.getCampgroundKey}&itemId=${userId}&action=user&_=${Date.now()}`;
        axios
          .get(url)
          .then((response) => {
            this.spinnerActive = false;
            this.userName =
              response.data.name || this.item.name
                ? response.data.name || this.item.name
                : response.data.email && response.data.email.includes('@privaterelay.apple')
                ? 'Anonymous through Apple'
                : response.data.email || 'N / A';
            this.item.loggedInUser = this.userName;
            this.item.loggedInEmail = response.data.email || 'N/A';
            this.item.communityId = response.data.communityId || 'N/A';
          })
          .catch((error) => {
            console.log('Error getting user:', error);
            this.spinnerActive = false;
          });
        //}
        //});
      } catch {
        this.userName = 'No User ID';
      }
    },
    async getSchema() {
      const dbId = this.$route.params.itemId;
      const dbRef = this.getLocationRef().child('custom-forms').child(dbId).child('formSchema');
      await dbRef
        .once('value')
        .then((snapshot) => {
          if (snapshot.val()) {
            this.schema = snapshot.val();
          }
        })
        .catch((error) => {
          console.error('Error getting schema:', error);
        });
    },
    getMasterIDs() {
      // Loop through this.item as object as key, return if key is object and has a label variable
      // equal to 'Master' and a value variable equal to 'Checked'
      const masterIDs = [];
      this.schema.forEach((element) => {
        //1. Search for all form schema with masterId checked.
        //2. Iterate through all objects in items,
        //3. ... matching the label of the schema element to the label of the item
        //4. ... and push the value to the masterIDs.
        if (element.masterId === true) {
          for (const key in this.item) {
            if (this.item[key].label === element.label) {
              if (element.type === 'text') masterIDs.push(this.item[key].value);
              if (element.type === 'date') {
                const date = new Date(this.item[key].value);
                masterIDs.push(`${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`);
              }
            }
          }
        }
      });
      //Concatenate masterIDs array into a comma-delimited string
      this.masterIds = `${masterIDs.join(', ')}`;
    },
    editItem: function () {
      this.$emit('editItem', this.itemId);
    },
    markItem: function (value) {
      this.item.completed = value;
      this.$emit('action', 'mark-item', value, this.item.orderId || this.itemId, this.item);
    },
    formEntries: function () {
      let newParams = {
        ...this.$route.params,
        ...{
          context: `${this.currentDBContext}.entries`,
          action: 'view',
          itemId: this.itemId,
        },
      };
      this.$router.push({ name: 'listingNoMenuEdit', params: newParams });
    },
    bulkCheckItem: function () {
      //this.bulkCheck = !this.bulkCheck;
      this.$emit('bulkCheckItem', this.itemId, this.bulkCheck);
    },
  },
};
</script>

<style scoped>
.form-completed {
  color: green;
}
.form-denied {
  color: red;
}
.confirmation-section {
  padding: 0px 10px;
}
.hide-item {
  display: none;
}
.background-visibility {
  background-color: white;
}
.show {
  display: inline !important;
}
</style>
