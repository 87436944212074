import { Tooltip } from 'bootstrap';

export const clickOutsideDirective = {
  beforeMount: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      if (!(el == event.target || el.contains(event.target))) {
        if (binding.instance[el.id]) {
          binding.value(event);
        }
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unmounted: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  },
};

export function createTooltipDirective(placement) {
  return {
    mounted(el, binding) {
      let tooltip = new Tooltip(el, {
        title: binding.value,
        placement: placement,
        customClass: 'custom-tooltip',
        html: true,
      });

      const showTooltip = () => tooltip.show();
      const hideTooltip = () => tooltip.hide();

      el.addEventListener('mouseover', showTooltip);
      el.addEventListener('mouseout', hideTooltip);

      el._showTooltip = showTooltip;
      el._hideTooltip = hideTooltip;
      el._tooltipInstance = tooltip;
    },
    unmounted(el) {
      el.removeEventListener('mouseover', el._showTooltip);
      el.removeEventListener('mouseout', el._hideTooltip);
      el._tooltipInstance.dispose();
    },
  };
}
